import * as Sentry from "@sentry/browser";

export default function setupSentry() {
  console.log("Setting up Sentry");
  if (process.env.NODE_ENV !== "development") {
    try {
      Sentry.init({
        dsn: window.bridge.SENTRY_FRONTEND_DSN,
        release: window.bridge.VERSION,
        environment: window.bridge.ENV,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
      console.log("Sentry setup complete");
    } catch (error) {
      console.log("Error setting up Sentry");
      console.log(error);
    }
  }

  const debugErrorEl = document.querySelector(".DebugError");
  if (debugErrorEl) {
    setTimeout(() => {
      console.log("Sending test error to Sentry");
      throw new Error("Sentry Test");
    }, 1000);
  }
}
