import { Controller } from "@hotwired/stimulus";
import lottie from "lottie-web";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static values = {
    animation: String,
  };

  connect() {
    useIntersection(this);

    this.lottie = lottie.loadAnimation({
      container: this.element,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: this.animationValue,
    });
  }

  appear() {
    try {
      this.lottie.play();
    } catch (err) {
      // Low battery iPhone etc.
    }
  }
}
