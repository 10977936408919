import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 26,
      breakpoints: {
        768: {
          slidesPerView: 2.7,
          spaceBetween: 36,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 36,
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
  }

  handlePrevButtonClick() {
    this.swiper.slidePrev();
  }

  handleNextButtonClick() {
    this.swiper.slideNext();
  }
}
